import './App.css';
import Regions from './Regions';
import Tips from './Tips';
import Export from './Export';
import Todos from './Todos';
import Strength from './Strength';
import React, { useState, useEffect } from 'react';
import Players from './Players';
import { useLocation, useNavigate } from 'react-router-dom';

function App() {
  const [regions, setRegions] = useState([]);
  const [players, setPlayers] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState(null);
  const [cut_line, setCutLine] = useState(0);
  const [prize_fund, setPrizeFund] = useState(0);
  const [loading, setLoading] = useState(false);
  const [version, setVersion] = useState("?");
  const [error, setError] = useState(null);
  const [dataExport, setDataExport] = useState([]);
  const [exportSet, setExportSet] = useState('cut');
  const [strength, setStrength] = useState(false);

  const [disabled, setDisabled] = useState(new Set());

  const hash = useLocation();
  const navigate = useNavigate();

  // get data out of the hash
  useEffect(() => {
    let hashFragments = hash.hash.replace("#", "").split("/");

    //console.log("hashFragments", hashFragments);

    if (hashFragments.length > 1) {
      if (hashFragments[1] !== selectedRegion) {
        setSelectedRegion(hashFragments[1].toUpperCase());
      }
      if (hashFragments[2] !== undefined) {
        let x = hashFragments[2].split(",");
        x.forEach(p => {
          disabled.add(p);
        });
      }
    }

    fetch('/api/version')
      .then(response => response.json())
      .then(data => setVersion(data.version));
  }, [hash, disabled, selectedRegion]);

  // load regions
  useEffect(() => {
    fetch('/api/regions/NACS')
      .then(response => response.json())
      .then(data => setRegions(data.active_regions));
  }, []);

  // load players
  useEffect(() => {
    if (selectedRegion === null) return;

    setPlayers([]);

    setLoading(true);

    fetch(`/api/top100/NACS/${selectedRegion}`)
      .then(response => {
        if (response.status === 400) {
          throw new Error(response.statusText);
        }
        setError(null);
        return response.json();
      })
      .then(data => setPlayers(data))
      .catch(err => {
        console.log(err);
        setError(err.toString());
        setLoading(false);
      });

  }, [selectedRegion]);

  // set cut line and prize fund
  useEffect(() => {
    if (!players?.top100?.length > 0) return;

    setLoading(false);
    setCutLine(players.stats.yearly_stats.field_size);
    let prize_fund = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(players.stats.yearly_stats.prize_fund);
    setPrizeFund(prize_fund);
    setDataExport([]);
  }, [players, setDataExport]);


  useEffect(() => {
    if (!players?.top100?.length > 0) return;

    let es = [];
    let index = 0;

    switch (exportSet) {
      case "cut":
        //console.log("++cut_line", cut_line);
        while (es.length < cut_line
          && index <= players.top100.length) {
          let p = players.top100[index];
          //console.log("++cl-p", es.length, p);
          if (!disabled.has(p.player_id)) {
            es.push(p.player_id);
          }
          index++;
        }
        break;
      case "top32":
        //console.log("++top32", cut_line);
        while (es.length < 32 && index <= players.top100.length) {
          let p = players.top100[index];
          //console.log("++t32-p", es.length, p);

          if (!disabled.has(p.player_id)) {
            es.push(p.player_id);
          }
          index++;
        }
        break;
      default:
        //console.log("++all", cut_line);
        for (let i = 0; i < players.top100.length; i++) {
          let p = players.top100[i];
          if (!disabled.has(p.player_id)) {
            es.push(p.player_id);
          }
        }
    }
    //console.log("++++es ", es.length, es)
    setDataExport(es);
    calculateStrength();

  }, [exportSet, players, disabled, cut_line]);





  // toggle disabled players
  const toggleDisabled = (playerId) => {
    const updated = structuredClone(disabled);

    if (updated.has(playerId)) {
      updated.delete(playerId);
    } else {
      updated.add(playerId);
    }
    setDisabled(updated);

    let updatedArray = Array.from(updated);
    let newHash = `#/${selectedRegion}/${updatedArray.join(",")}`;
    navigate(newHash, { replace: true });
  }




  // calculate strength
  function calculateStrength() {
    if (!players?.top100?.length > 0) return;

    // exclude disabled players
    let currentField = players.top100.filter(p => {
      return !disabled.has(p.player_id);
    });
    // cut to cut line
    currentField = currentField.slice(0, cut_line);

    // calculate rating and ranking values
    let rating = 0;
    let ranking = 0;
    let ratedPlayerCount = 0;
    currentField.forEach((p, i) => {
      let a = Math.max(0, (p.stats.system.open.ratings_value * 0.000546875) - 0.703125);
      let b = Math.max(0, Math.log(p.stats.system.open.current_rank) * -0.211675054 + 1.459827968);

      //console.log("+++ ", p.player_name, a, b);

      if (p.stats.system.open.total_events_all_time > 5) {
        ratedPlayerCount++
      }

      rating += a;
      ranking += b;
    });

    let tgp = 100;
    switch (cut_line) {
      case 8:
        tgp = 68;
        break;
      case 16:
        tgp = 88;
        break;
      default:
        tgp = 100;
    }

    let booster = 150;
    let base = ratedPlayerCount * 0.5;
    let value = base + rating + ranking;

    let str = {
      rating: rating,
      ranking: ranking,
      base: base,
      tgp: tgp,
      value: value,
      booster: booster,

      tourValue: (booster * tgp * value / 10000).toFixed(2)
    };

    setStrength(str);

    //console.log("+++strength ", selectedRegion, str);
  }





  // select new region
  function selectNewRegion(region) {
    setDisabled(new Set());
    navigate(`#/${region}`, { replace: true });
    setDataExport([]);
    setPlayers([]);
    setStrength(false);
  };

  return (
    <div className="App">

      <header className="App-header">
        <h1>IFPA Standings</h1>
      </header>

      <Tips />

      <Regions regions={regions} selectedRegion={selectedRegion} selectNewRegion={selectNewRegion} nav={navigate} />

      {error ? <div className="Error">{error}</div> : null}

      {dataExport.length ? <Export dataExport={dataExport} setExportSet={setExportSet} /> : null}

      {strength && <Strength strength={strength} />}

      {selectedRegion &&
        <Players
          players={players}
          disabled={disabled}
          loading={loading}
          cut_line={cut_line}
          prize_fund={prize_fund}
          selectedRegion={selectedRegion}
          toggleDisabled={toggleDisabled}
        />
      }

      <Todos version={version} />

    </div >
  );
}

export default App;

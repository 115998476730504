import React from 'react';

const Todos = ({ version }) => {

  return (

    <div className="Todos">
      <h3>Todo (ver {version})</h3>
      <ul>
        <li>Women's version</li>
        <li className="done">DONE - Show Region reps</li>
        <li className="done">DONE - Show tournament count per player</li>
        <li className="done">DONE - Save/Share spectulations</li>
        <li className="done">DONE - Export player IDs</li>
        <li className="done">DONE - Show tournament value</li>
        <li>Toggle ability to see if players are close in another state, rather than already above the cut line</li>
        <li>Add prize money breakdown somewhere</li>
        <li>Questions? Feedback? Hate mail? Join on <a href="https://discord.gg/JM4JsH9C">Discord</a></li>
      </ul>
    </div>

  );
}

export default Todos;
import React from 'react';

const Tips = () => {

  const [show, setShow] = React.useState(true);

  return (

    <div className="Tips">
      <h3>
        Tips
        {show ? <span onClick={() => setShow(false)}>(hide)</span> : <span onClick={() => setShow(true)}>(show)</span>}
      </h3>
      {show ?
        <ul>
          <li>Select a region, then wait, then...</li>
          <li>See what the current rankings are</li>
          <li>See which other regions the players are qualified for, and that region's (qualifers, prize pool)</li>
          <li>NACS-XX or WNACS-XX means that player is the region/state rep for region XX</li>
          <li>Click those other regions or "Decline XX" to see what happens if that player doesn't play in the selected region. Click again to toggle.</li>
          <li>See where the cut line falls!</li>
          <li>Copy & paste the URL to your friends so they can see what you came up with!</li>
          <li>Export the player IDs for import into Matchplay</li>
          <li>*** Data is cached for 1 hour to avoid excessive calls to the IFPA APIs ***</li>
          <li>Questions? Feedback? Hate mail? Join on <a href="https://discord.gg/JM4JsH9C">Discord</a></li>
        </ul> : null}
    </div>

  );
}

export default Tips;
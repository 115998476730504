import React from 'react';

const Players = ({ players, disabled, loading, selectedRegion, cut_line, prize_fund, toggleDisabled }) => {

  let pos = 0;

  return (
    <div className="Players">
      <>
        {players.length < 1 ?
          (loading ? <p><img alt="Loading..." src='/loading.svg' width='100' /></p> : <p>Select a region...</p>) : (
            <>
              <h2>Top 100 (Qualifiers: {cut_line}, Prize Pool: {prize_fund})</h2>
              {players.top100.map(p => {

                let dis = disabled.has(p.player_id);
                return (
                  < React.Fragment key={`${p.player_id}_wrapper`}>
                    <dl key={p.player_id}>
                      <dt>{dis ? "---" : <span className="PlayerRank">#{++pos}</span>} {p.player_name} ({p.city} {p.stateprov_code}) - {p.wppr_points} (Events: {p.event_count})</dt>
                      <dd>
                        {p?.rep?.NACS ?
                          <span className={"StateRep" + (dis ? " disabled" : "")}
                            title={`NACS Rep for ${p.rep.NACS}`}
                            onClick={() => toggleDisabled(p.player_id)}>{p.rep.NACS}</span>
                          : null
                        }
                        {p?.rep?.WNACS ?
                          <span className={"StateRep" + (dis ? " disabled" : "")}
                            title={`NACS Rep for ${p.rep.NACS}`}
                            onClick={() => toggleDisabled(p.player_id)}>{p.rep.WNACS}</span>
                          : null
                        }
                        {p.series.map((i) => {
                          return (
                            (i.series_rank <= i.field_size && i.region_code !== selectedRegion) ?
                              <span key={`${p.player_id}-${i.region_code}`} className={"StateRank" + (dis ? " disabled" : "")}
                                onClick={() => toggleDisabled(p.player_id)}>{i.region_code} #{i.series_rank} ({i.field_size} - {i.prize_fund})</span>
                              : null
                          )
                        })}
                        <span className={"StateRank" + (dis ? " disabled" : "")} onClick={() => toggleDisabled(p.player_id)}>Decline {selectedRegion}</span>
                      </dd>
                    </dl>
                    {pos === cut_line ? <div style={{ clear: "both" }} className="CutLine">------ CUT LINE ------</div> : null}
                  </React.Fragment>
                )
              })}
            </>
          )}
      </>
    </div >
  )
}

export default Players;
import React from 'react';

const Strength = ({ strength }) => {

  const [show, setShow] = React.useState(true);

  // rating: rating,
  // ranking: ranking,
  // base: base,
  // tgp: tgp,
  // value: value,

  // tourValue: (booster * tgp * value / 10000).toFixed(2)

  return (

    <div className="Strength">
      <h3>
        Estimated value: {strength.tourValue} WPPRs
        {show ? <span onClick={() => setShow(false)}> (hide) </span> : <span onClick={() => setShow(true)}>(show)</span >}
      </h3>
      {show ?
        <ul>
          <li>Ranking Strength: {parseFloat(strength.ranking).toFixed(2)}</li>
          <li>Rating Strength: {parseFloat(strength.rating).toFixed(2)}</li>
          <li>Base Value: {strength.base}</li>
          <li>Total (Before TGP): {parseFloat(strength.value).toFixed(2)}</li>
          <li>TGP: {strength.tgp}%</li>
          <li>Event Booster: {strength.booster}%</li>
          <li>Tour Value: {strength.tourValue}</li>
          <li>*This estimate is an estimate ...and is based on current data rather than final yearend data.</li>
        </ul> : null}
    </div>

  );
}

export default Strength;
import React from 'react';

const Export = ({ dataExport, setExportSet }) => {

  return (
    <div className="Export">Export
      <select onChange={(e) => setExportSet(e.target.value)}>
        <option value="cut">Above Cut Line (not disabled)</option>
        <option value="all">All (including disabled)</option>
        <option value="top32">Top 32 (not disabled)</option>
      </select>
      {/* <textarea defaultValue={dataExport.length ? dataExport.join('\r\n') : null} /> */}
      <button onClick={(e) => { navigator.clipboard.writeText(dataExport.join('\r\n')); }}>Copy</button>
    </div>

  );
}

export default Export;
import React from 'react';

const Regions = ({ regions, selectedRegion, selectNewRegion, nav }) => {
  return (
    <div className="Regions">
      <h2>NACS Regions</h2>
      <select onChange={(e) => selectNewRegion(e.target.value)} value={selectedRegion ? selectedRegion : ''}>
        <option value="">Select a region</option>
        {regions.map(r =>
          <option key={r.region_code} value={r.region_code}>
            {r.region_name}
          </option>
        )}
      </select>
    </div>
  )
};

export default Regions;